import $ from 'jquery';
import 'bootstrap/js/src/carousel';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/modal';
import Cookies from 'js-cookie';
import {rIC} from 'idlize/idle-callback-polyfills.mjs';
import {lazyLoad} from './helpers/lazyLoadHelpers';
import abTestService from './services/abTestService';
import contentService from './services/contentService';
import userService from './services/userService';
import ActiveCampaignNewsletters from './components/newsletter/ActiveCampaignNewsletters';
import Newsletter from './components/newsletter/Newsletter';
import PrizeGamePopup from './components/newsletter/PrizeGameModal';
import {initCommonUserActions} from './user/components/commonFunctions';
import MainBenefitsBanner from './components/MainBenefitsBanner';
import './services/sentryService';

window.$ = $;

class BaseLayout {
	constructor() {
		this.init();
	}

	init() {
		this.processEarlyErrors();
		this.initContentLazyLoad();
		this.initHeaderArrowController();
		initCommonUserActions()
			.catch(console.error);
		
		this.mobileOpenMenu();
		this.initNewsletters();

		window.addEventListener(
			'load',
			() => {
				abTestService.init();
				this.initCookieBar();
				this.initEvents();
				this.initLangSelector();
				this.initSiteLinksPrefetch();
				new MainBenefitsBanner();
				new PrizeGamePopup();
				this.floatFooterPanel();
			},
			{
				'once': true
			}
		);

		rIC(() => {
			contentService.initToolTips();
			requestAnimationFrame(() => {
				this.initHeaderLeftMenu();
				
				contentService.initPopovers({
					'params': contentService.customPopOverMarkup()
				});
			});
		});
	}
	
	processEarlyErrors() {
		// log early errors, stored without Sentry
		if (!window.globalParams.SENTRY_DSN && Array.isArray(window.earlyErrors)) {
			window.earlyErrors.forEach((err) => console.error(err));
		}
	}
	
	initEvents() {
		const userNavBar = document.getElementById('userNavBar');
		
		if (userNavBar) {
			userNavBar.addEventListener('show.bs.collapse', (ev) => {
				if (!userService.isUserLoggedIn()) {
					ev.preventDefault();
				}
			});
		}
		
		const toggleElements = document.querySelectorAll(('header [data-bs-toggle="collapse"]'));
		
		$(toggleElements).on('click', (ev) => {
			contentService.closeOtherOpenedNavBars(toggleElements, ev.currentTarget);
		});
		
		$('#headerBanner i').one('click', () => {
			$('#headerBanner').hide();
		});
		
		$('#closeInfoLine, #closeInfoLine i').one('click', () => {
			$('#infoLine, #headerArrow').hide();
			$('.showInfoLine').removeClass('showInfoLine');
		});
		
		$('#closeSystemUpdateMessage i').one('click', () => {
			$('.systemUpdateMessage').hide();
		});
		
		$('#CookiebotShow').on('click', (ev) => {
			ev.preventDefault();
			Cookiebot?.show();
		});
	}

	initHeaderLeftMenu() {
		const leftNavbarMenuElement = document.getElementById('navbarMenu_left');
		
		if (!leftNavbarMenuElement) {
			return;
		}
		
		contentService.enhanceCollapse(leftNavbarMenuElement);
		
		const navbarSourceElement = document.querySelector('.mobileMenuOpener');
		
		leftNavbarMenuElement.addEventListener('show.bs.collapse', () => {
			navbarSourceElement.classList.add('opened');
		});
		
		leftNavbarMenuElement.addEventListener('hide.bs.collapse', () => {
			navbarSourceElement.classList.remove('opened');
		});
	}
	
	mobileOpenMenu() {
		const NAV		  = $('NAV.originalMenu'),
			  hasInfoLine = $('#infoLine').length,
			  setNavToTop = () => {
				  if (document.documentElement.scrollTop) {
					  NAV.addClass('navIsScrolled');
				  } else if (hasInfoLine) {
					  NAV.removeClass('navIsScrolled');
				  }
			  };

		$(window).on('scroll load', () => {
			requestAnimationFrame(setNavToTop);
		});
	}

	initCookieBar() {
		if (!Cookies.get('cookiebar')) {
			$('#cookiebar').removeClass('hidden-element');
		}

		$('#closeCookieBar').one('click', (ev) => {
			$('#cookiebar').remove();
			$('.logos').removeClass('showCookieBar');
			Cookies.set(
				'cookiebar',
				'accepted',
				{
					'expires': 365
				}
			);

			ev.preventDefault();
		});
	}
	
	initLangSelector() {
		contentService.initPopovers({
			'selector': '.languageSelector',
			'params'  : {
				'trigger': 'click'
			}
		});
	}

	initSiteLinksPrefetch() {
		// IE10 doesn't support dataset, but instantPage needs it
		if (typeof document.body.dataset !== 'undefined') {
			require('instant.page'); // eslint-disable-line unicorn/prefer-module
		}
	}

	initContentLazyLoad() {
		lazyLoad();

		const modals = document.querySelectorAll('.modal');

		modals.forEach((modal) => {
			modal.addEventListener('shown.bs.modal', () => {
				lazyLoad(
					{
						'elements_selector': '.modal .lazy'
					},
					modal.querySelectorAll('.lazy')
				);
			}, {'once': true});
		});
	}

	initHeaderArrowController() {
		if (Cookies.get('clickedHeaderReviewsLink') !== 'clicked') {
			$('#headerArrow').removeClass('hidden');
		}
	}
	
	initNewsletters() {
		const activeCampaignNewsletterRenderElement = document.querySelector('.active-campaign-newsletter-render-element');
		
		if (activeCampaignNewsletterRenderElement) {
			new ActiveCampaignNewsletters(activeCampaignNewsletterRenderElement);
		} else {
			rIC(() => {
				const newsletterForms = document.querySelectorAll('form.newsletter_sign_up');
				
				if (newsletterForms.length) {
					newsletterForms.forEach((form) => {
						new Newsletter(form);
					});
				}
			});
		}
	}
	
	floatFooterPanel() {
		if (!Cookies.get('floatfooterpanel')) {
			$('.floatFooterPanel .accordion').addClass('twist');
			
			setTimeout(() => {
				$('.floatFooterPanel .accordion').removeClass('twist');
				
				Cookies.set(
					'floatfooterpanel',
					'showed',
					{
						'expires': 365
					}
				);
			}, 1250);
		}
	}
}

new BaseLayout();