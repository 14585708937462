const ms = require('ms');

/**
 * Common constants for the backend and the frontend!
 */

module.exports = {
	'USER_ID_LENGTH'                         : 13, // don't change it without fixing old values!
	'SESSION_COOKIE_NAME'                    : 'PHPSESSID',
	'SESSION_LENGTH_MS'                      : ms('1 year'), // this is how long we'll store uer sessions
	'DEFAULT_COOKIE_EXPIRATION_IN_DAYS'      : 365,
	'RECENTLY_VIEWED_ARRANGEMENT_LIMIT'      : 5,
	'RECENTLY_VIEWED_EVENT_LIMIT'            : 3,
	'MAX_FAVOURITE_ARRANGEMENT_ID_COUNT'     : 30,
	'MAX_STORED_PACKAGE_SEARCHES'            : 4,
	'PASSWORD_MIN_LENGTH'                    : 8,
	'PASSWORD_MAX_LENGTH'                    : 100,
	'REQUIRE_RE_CAPTCHA_AFTER_LOGIN_ATTEMPTS': 4,
	'INVALID_CREDENTIALS_PUNISHMENT_DELAY_MS': 2000,
	'USER_ROUTES_LABEL_ID_WHITE_LIST'        : ['vbt', 'fbt', 'spm'],
	/** @enum {string} */
	'USER_STATUSES'                          : {
		'UNCONFIRMED': 'unconfirmed',
		'ACTIVE'     : 'active',
		'LOCKED'     : 'locked',
		'REMOVED'    : 'removed'
	},
	/** @enum {number} */
	'UNCONFIRMED_USERS': {
		'REMINDER_EMAILS_JOB_FREQUENCY_HOURS': 12,
		'FIRST_REMINDER_EMAIL_AFTER_DAYS'    : 1,
		'LAST_REMINDER_EMAIL_AFTER_DAYS'     : 3,
		'EMAIL_CONFIRMATION_DEADLINE_DAYS'   : 7
	},
	/** @enum {string} */
	'EMAIL_TYPES': {
		'EMAIL_CONFIRMATION'           : 'email_confirmation',
		'REQUEST_PASSWORD_RESET'       : 'request_password_reset',
		'CONFIRMATION_REMINDER'        : 'registration_reminder',
		'REQUEST_PASSENGER_DATA_CHANGE': 'update_passenger_data',
		'REQUEST_FLIGHT_CHANGE'        : 'request_booked_flight_change'
	},
	/** @enum {number} */
	'JWT_TOKEN_VALIDATIONS': {
		'EMAIL_CONFIRMATION': ms('1 week'),
		'FORGOTTEN_PASSWORD': ms('2 days')
	},
	
	/** @enum {string} */
	'COOKIE_KEYS': {
		'USER_DATA'                   : 'UDATA',
		'USER_ID'                     : 'UID',
		'FAVOURITE_ARRANGEMENT_IDS'   : 'favArrangeIds',
		'RECENTLY_VIEWED_EVENTS'      : 'viewedEvs',
		'RECENTLY_VIEWED_ARRANGEMENTS': 'viewedArrs',
		'PACKAGE_SEARCHES'         	  : 'packageSearches'
	},
	/** @enum {string} */
	'VIEWED_ITEM_TYPES': {
		'EVENT'      : 'event',
		'ARRANGEMENT': 'arrangement'
	}
};